<script setup>
import { computed, onMounted } from 'vue';
import { Link } from '@inertiajs/vue3';
import { useApplicationStore } from '@/stores/application.js';
import unauthorisedResponseImageLight from '@publicImages/error-401-light.svg';
import serverErrorResponseImageLight from '@publicImages/error-500-light.svg';
import pageNotFoundResponseImageLight from '@publicImages/error-404-light.svg';
import tooManyRequestsResponseImageLight from '@publicImages/error-429-light.svg';
import unuathorisedResponseImageDark from '@publicImages/error-401-dark.svg';
import serverErrorResponseImageDark from '@publicImages/error-500-dark.svg';
import pageNotFoundResponseImageDark from '@publicImages/error-404-dark.svg';
import tooManyRequestsResponseImageDark from '@publicImages/error-429-dark.svg';

const props = defineProps({
    status: Number,
    request_id: String,
})

const title = computed(() => {
    return {
        503: '503: Service Unavailable',
        500: '500: Server Error',
        429: '429: Too Many Requests',
        419: '419: Page Expired',
        404: '404: Page Not Found',
        403: '403: Forbidden',
        401: '401: Unauthorised',
    }[props.status]
})

const description = computed(() => {
    return {
        503: 'Sorry, we are doing some maintenance. Please check back soon.',
        500: 'Whoops, something went wrong on our servers.',
        429: 'Sorry, you are making too many requests to our servers.',
        419: 'Sorry, the page has expired.',
        404: 'Sorry, the page you are looking for could not be found.',
        403: 'Sorry, you are forbidden from accessing this page.',
        401: 'Sorry, you are unauthorised from accessing this page.',
    }[props.status]
})
const createImageObject = (lightImage, darkImage) => {
    return {
        light: lightImage,
        dark: darkImage,
    };
}

const shouldShowErrorId = computed(() => {
    return props.status !== 403 && props.status !== 404 && props.status !== 401;
});

const errorImage = createImageObject(serverErrorResponseImageLight, serverErrorResponseImageDark);
const tooManyRequestsImage = createImageObject(tooManyRequestsResponseImageLight, tooManyRequestsResponseImageDark);
const notFoundImage = createImageObject(pageNotFoundResponseImageLight, pageNotFoundResponseImageDark);
const unauthorisedImage = createImageObject(unauthorisedResponseImageLight, unuathorisedResponseImageDark);

const image = computed(() => {
    return {
        503: errorImage,
        500: errorImage,
        429: tooManyRequestsImage,
        419: errorImage,
        404: notFoundImage,
        403: unauthorisedImage,
        401: unauthorisedImage,
    }[props.status]
});


const applicationStore = useApplicationStore();

</script>
<template>
    <div
        id="root"
        class="min-h-100vh flex grow bg-slate-50 dark:bg-navy-900"
    >
        <main class="grid w-full grow grid-cols-1 place-items-center">
            <div class="max-w-2xl p-6 text-center">
                <div class="w-full max-w-md">
                    <img
                        v-if="!applicationStore.isDarkMode"
                        class="w-full"
                        :src="image.light"
                        alt="image"
                    />
                    <img
                        v-else
                        class="w-full"
                        :src="image.dark"
                        alt="image"
                    />
                </div>
                <p
                    class="pt-4 text-xl font-semibold text-dark-text dark:text-button-dark-text"
                >
                    {{ title }}
                </p>
                <p class="pt-2 text-dark-text dark:text-light-text">
                    {{ description }}
                </p>
                <p class="text-base text-primary-light dark:text-light-text mt-4" v-if="shouldShowErrorId">
                    Error ID: {{ props.request_id }}
                </p>
                <Link
                    :href="route('dashboard')"
                    class="btn mt-8 h-11 bg-primary-light text-base font-medium text-button-light-text hover:bg-primary-light hover:shadow-lg hover:shadow-primary-light/50 focus:bg-primary-light focus:shadow-lg focus:shadow-primary-light/50 active:bg-primary-light/90 dark:bg-primary-dark dark:hover:bg-primary-dark/50 dark:hover:shadow-primary-dark/50 dark:focus:bg-primary-dark/50 dark:focus:shadow-accent-dark/50 dark:active:bg-primary-dark/90"
                >
                    Return Home
                </Link>
            </div>
        </main>
    </div>
</template>
